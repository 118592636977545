$first-color: rgb(0, 168, 48);
$second-color: rgb(255,255,255);
$third-color: rgb(255, 0, 20);
$first-color-alpha: rgba(0, 168, 48, 1);
$second-color-alpha: rgba(56, 62, 66, 1);
$third-color-alpha: rgba(255, 0, 20, 0.1);

nav .active {
	background-color: $first-color;
}

nav {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: $second-color;
	color: $third-color;
	position: sticky;
	top: 0;
}

nav .title {
	font-size: x-large;
	margin: 1rem;
	font-weight: bolder;
	text-decoration: none;
	color: $first-color;
}

nav ul {
	display: flex;
	margin: 0;
	margin-right: 0.25rem;
	padding: 0;
}

nav ul li {
	list-style: none;
}

nav ul li a {
	display: block;
	text-decoration: none;
	font-weight: bold;
	text-transform: uppercase;
	color: $third-color;
	padding: 0.5rem;
	margin: 0 0.5rem;
	border-radius: 0.5rem;
	cursor: pointer;
}

nav ul li a:not(.active):hover {
	scale: 1.2;
	background-color: $third-color-alpha;
}

.hamburger-div {
	display: none;
	position: absolute;
	top: 0.5rem;
	right: 0.75rem;
	flex-direction: column;
	justify-content: space-between;
}

@media screen and (max-width: 500px) {
	.hamburger-div {
		display: flex;
	}

	.hamburger-div:hover {
		scale: 1.2;
	}

	nav {
		flex-direction: column;
		align-items: flex-start;
		min-width: 100%;
	}

	nav ul {
		display: none;
		flex-direction: column;
		width: 100%;
		margin-bottom: 0.25rem;
		margin-right: 0;
	}

	nav ul.open {
		display: flex;
		animation: menu-slide-down infinite 0.1s linear;
		animation-iteration-count: calc(1);
		-webkit-animation: menu-slide-down infinite 0.1s linear;
		-webkit-animation-iteration-count: calc(1);
	}

	nav ul li {
		width: 100%;
		text-align: center;
	}

	nav ul li a {
		margin: 0.2rem 0.5rem;
	}

	@keyframes menu-slide-down {
		from {
			opacity: 0;
			transform: translateY(-50px);
		}
		to {
			opacity: 1;
			transform: translateY(0px);
		}
	}
}
