$first-color: rgb(0, 168, 48);
$second-color: rgb(255,255,255);
$third-color: rgb(255, 0, 20);
$first-color-alpha: rgba(0, 168, 48, 1);
$second-color-alpha: rgba(56, 62, 66, 1);
$third-color-alpha: rgba(255, 0, 20, 0.1);

.contact {
	text-align: center;
	width: 30%;
}

.contact form {
	background-color: white;
	border-radius: 10px;
	width: 100%;
	margin-bottom: 2em;
}

.contact form h1 {
	color: $third-color;
	text-align: center;
	padding-top: 0.5em;
}

.contact form button {
	padding: 1rem;
	border: none;
	background-color: $third-color;
	color: white;
	border-radius: 5px;
	font-weight: bold;
	font-size: 1.5rem;
	cursor: pointer;
	margin: 1.5rem 1.5rem;
}

.formInputText {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.formInputText input {
	padding: 1.5rem;
	margin: 0.5rem 1rem;
	border: none;
	border-radius: 2px;
	border-bottom: 1px solid gray;
	text-align: center;
}

.contact form textarea {
	padding: 1.5rem;
	margin: 0rem 1rem;
	border-radius: 5px;
	border: 1px solid gray;
	width: 85%;
}

.formLabelText {
	display: flex;
	flex-direction: column;
	font-size: 1em;
	color: gray;
	margin-top: 2rem;
	width: 100%;
}

.formInputText input:focus {
	border-bottom: 1px solid gray;
}

.formInputText input::placeholder {
	font-size: 1.2em;
	opacity: 0.7;
}

.formInputText p {
	color: gray;
}

.errorMessage {
	font-size: 0.75em;
	padding: 0.3rem;
	color: red;
	display: none;
}

.contact input:invalid[focused='true'] {
	border-bottom: 3px solid red;
}

.contact input:invalid[focused='true'] ~ .errorMessage {
	display: block;
}

// ---------------- SWITCH ---------------- \\

.formLabelSwitch {
	position: relative;
	width: 60px;
	height: 34px;
}

.formLabelSwitch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 34px;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	border-radius: 50%;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.formLabelSwitch input:checked + .slider {
	background-color: #2196f3;
}

.formLabelSwitch input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}

.formLabelSwitch input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

@media screen and (max-width: 500px) {
	.contact {
		width: 100%;
	}
	.contact form {
		background-color: $second-color;
	}
	.contact form label {
		color: white;
	}
}
