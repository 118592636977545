$first-color: rgb(0, 168, 48);
$second-color: rgb(255,255,255);
$third-color: rgb(255, 0, 20);
$first-color-alpha: rgba(0, 168, 48, 1);
$second-color-alpha: rgba(56, 62, 66, 1);
$third-color-alpha: rgba(255, 0, 20, 0.1);

.home {
	flex-direction: column;
	text-align: center;
	background-color: $second-color;
	color: $third-color;
	min-height: 100vh;
	max-width: 100%;
}

.home .info {
	width: 100%;
	text-align: center;
	justify-content: center;
	align-items: flex-start;
	display: flex;
	flex-direction: row;
}

.home img {
	width: 30vw;
}

.home .text {
	padding-left: 0.5em;
	padding-right: 0.5em;
	display: block;
	text-align: left;
	justify-content: center;
	color: black;
	font-size: 2em;
}

.home a {
	color: $first-color;
	text-transform: uppercase;
	cursor: pointer;
}

.home a:hover {
	font-style: italic;
}

@media screen and (max-width: 500px) {
	.home .info {
		flex-direction: column;
	}

	.home img {
		width: 100%;
	}

	.home .text {
		padding-left: 0.5em;
		padding-right: 0.5em;
		width: 90%;
	}
}
