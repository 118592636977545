$first-color: rgb(0, 168, 48);
$second-color: rgb(255,255,255);
$third-color: rgb(255, 0, 20);
$first-color-alpha: rgba(0, 168, 48, 1);
$second-color-alpha: rgba(56, 62, 66, 1);
$third-color-alpha: rgba(255, 0, 20, 0.1);

.overMe {
	flex-direction: column;
	text-align: center;
	background-color: $second-color;
	color: $third-color;
	min-height: 100vh;
	max-width: 60%;
}

.overMe p {
	font-size: x-large;
	padding-left: 0.5em;
	padding-right: 0.5em;
	display: block;
	text-align: left;
	justify-content: center;
	color: black;
}

.overMe a {
	color: $first-color;
	text-transform: uppercase;
	cursor: pointer;
}

.overMe a:hover {
	font-style: italic;
}

@media screen and (max-width: 500px) {
	.overMe .info {
		flex-direction: column;
	}

	.overMe img {
		width: 100%;
	}

	.overMe p {
		padding-left: 0.5em;
		padding-right: 0.5em;
		width: 90%;
	}
}
