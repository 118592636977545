$first-color: rgb(0, 168, 48);
$second-color: rgb(255,255,255);
$third-color: rgb(255, 0, 20);
$first-color-alpha: rgba(0, 168, 48, 1);
$second-color-alpha: rgba(56, 62, 66, 1);
$third-color-alpha: rgba(255, 0, 20, 0.1);

.dataProtection {
	padding: 2em;
}

.dataProtection a {
	color: $first-color;
	text-transform: uppercase;
	cursor: pointer;
}

.dataProtection a:hover {
	font-style: italic;
}

@media screen and (max-width: 500px) {
	.dataProtection {
		width: 100%;
		word-wrap: break-word;
	}
}
