$first-color: rgb(0, 168, 48);
$second-color: rgb(255,255,255);
$third-color: rgb(255, 0, 20);
$first-color-alpha: rgba(0, 168, 48, 1);
$second-color-alpha: rgba(56, 62, 66, 1);
$third-color-alpha: rgba(255, 0, 20, 0.1);

body {
	background-color: $first-color;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
		'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
		'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.module {
	margin-top: 0.25rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	background-color: $second-color;
	color: black;
	min-height: 100vh;
	min-width: 100%;
	z-index: -100;
}

@media screen and (max-width: 500px) {
	.module {
		margin: 0;
		min-width: 100%;
	}
}
