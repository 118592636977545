$first-color: rgb(0, 168, 48);
$second-color: rgb(255,255,255);
$third-color: rgb(255, 0, 20);
$first-color-alpha: rgba(0, 168, 48, 1);
$second-color-alpha: rgba(56, 62, 66, 1);
$third-color-alpha: rgba(255, 0, 20, 0.1);

.App {
	text-align: center;
	max-width: 100%;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: $second-color;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
	min-height: 100vh;
}

.App-link {
	color: $first-color;
}

@keyframes App-logo-spin {
	0% {
		opacity: 0;
		transform: scale(0);
		transform: translateX(-100%);
	}
	25% {
		opacity: 1;
		transform: scale(1);
		transform: translateX(-50%);
	}
	75% {
		opacity: 1;
		transform: scale(1);
		transform: translateX(50%);
	}
	100% {
		opacity: 0;
		transform: scale(0);
		transform: translateX(100%);
	}
}
