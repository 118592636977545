$first-color: rgb(0, 168, 48);
$second-color: rgb(255,255,255);
$third-color: rgb(255, 0, 20);
$first-color-alpha: rgba(0, 168, 48, 1);
$second-color-alpha: rgba(56, 62, 66, 1);
$third-color-alpha: rgba(255, 0, 20, 0.1);

.foobar .active {
	background-color: $third-color-alpha;
}

.foobar {
	background-color: $first-color;
	color: $third-color;
}

.foobar ul li a {
	display: block;
	text-decoration: none;
	text-transform: uppercase;
	color: $second-color;
	padding: 0.5rem;
	margin: 0 0.5rem;
	border-radius: 0.5rem;
	cursor: pointer;
}

.foobar ul li a:not(.active):hover {
	scale: 1.2;
	background-color: $third-color-alpha;
	border-radius: 0.5rem;
}

.foobar ul {
	display: flex;
	text-align: left;
	margin-right: 0.25rem;
	padding: 0;
	justify-content: right;
}

.foobar ul li {
	list-style: none;
}

@media screen and (max-width: 500px) {
	.foobar ul {
		justify-content: center;
	}
}
