$first-color: rgb(0, 168, 48);
$second-color: rgb(255,255,255);
$third-color: rgb(255, 0, 20);
$first-color-alpha: rgba(0, 168, 48, 0.25);
$second-color-alpha: rgba(56, 62, 66, 1);
$third-color-alpha: rgba(255, 0, 20, 0.1);

$tableWidth: 100%;

.prices h1 {
	color: $third-color
}

.prices a {
	color: $first-color;
	text-transform: uppercase;
	cursor: pointer;
}

.prices a:hover {
	font-style: italic;
}

.prices {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

.prices img {
	width: 50%;
}

.prices table {
	border-collapse: collapse;
	margin: 0;
	width: $tableWidth;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.prices table tr {
	padding: .45em;
}

.prices table thead tr {
	width: $tableWidth;
	font-size: x-large;
	font-weight: bold;
	color: $second-color;
	background-color: $first-color;
	text-transform: uppercase;
	border-bottom: 3px solid $third-color;
}

.prices table thead tr th {
	padding: .5em 1.5em
}

.prices table tbody tr:nth-child(even) {
	background-color: $first-color-alpha;
}

.prices table tbody tr td {
	font-size: 1.75em;
	padding: 1em;
	text-align: center;
}

@media screen and (max-width: 1024px) {
	.prices table {
		border: 3px solid $first-color;
	}

	.prices table thead tr {
		display: none;
	}

	.prices table tr {
		display: block;
	}

	.prices table tbody tr td, .prices table thead tr th {
		padding: .5em
	}

	.prices table tbody tr td {
		text-align: right;
		display: block;
		font-size: 1.25em;
	}

	.prices table tbody tr td::before {
		font-weight: bold;
		content: attr(data-title) ": ";
		float: left;
	}

	.prices table tbody tr:nth-child(even) {
		background-color: $first-color-alpha;
	}
}